import React from "react"
import NoheaderFooter from "../../../../components/Noheaderfooter"
import SEO from "../../../../components/seo"
import PrivacyMobileUk from "../../../../components/body/pages/mobile/uk/legal/privacy"

const PrivacyUkPage = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/en-gb/legal/privacy-policy/"}
      title="Privacy Policy | Kuda | The Money App for Africans"
    />
    <PrivacyMobileUk />
  </NoheaderFooter>
)

export default PrivacyUkPage
